import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Helmet from 'react-helmet'

import * as Types from 'types'

import { useApolloClient } from '@apollo/react-hooks'

import { useParams } from 'react-router-dom'

import { Pane, Heading, Paragraph, Text } from 'evergreen-ui'

import SiteFooter from 'components/site-footer'

import FAQCard from 'components/faq-card'
import { usePublicGlobal } from 'components/public-global-provider'

import {
  Button,
  Icon,
  OfficeCard,
  DentistCard,
  Link,
  colors,
  prettyPhoneNumber,
  PlanCardListNew,
  Spinner
} from '@pearly/lib'

export type Props = {
  className?: string
}

const colorMap = {
  [Types.SiteColor.BLUE]: { buttonIntent: 'none' as const, iconColor: 'primary' as const },
  [Types.SiteColor.GREEN]: { buttonIntent: 'success' as const, iconColor: 'success' as const },
  [Types.SiteColor.ORANGE]: { buttonIntent: 'warning' as const, iconColor: 'warning' as const },
  [Types.SiteColor.RED]: { buttonIntent: 'danger' as const, iconColor: 'danger' as const }
}

const ProviderMobile = ({ className }: Props) => {
  const { providerParam } = useParams<{ providerParam: string }>()
  const enrollUrl = `/${providerParam}/enroll`
  const apolloClient = useApolloClient()

  const { global } = usePublicGlobal()
  const account = global?.account_public

  const { buttonIntent, iconColor } = colorMap[account?.siteColor ?? Types.SiteColor.BLUE]

  const [headerElevation, setHeaderElevation] = useState(false)

  useEffect(() => {
    window.onscroll = e => {
      if (window.scrollY > 0) {
        setHeaderElevation(true)
      } else {
        setHeaderElevation(false)
      }
    }
  }, [])

  return (
    <>
      <Helmet>
        <title>{global?.account_public?.programName}</title>
        {providerParam !== 'demo' && <link rel="canonical" href={process.env.GATSBY_MEMBER_URL} />}
      </Helmet>
      <div className={className}>
        <Pane
          gridArea="header"
          paddingX="32px"
          width="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          position="fixed"
          zIndex={3}
          height="80px"
          backgroundColor="white"
          elevation={headerElevation ? 1 : undefined}
          transition="box-shadow 240ms"
        >
          <Pane lineHeight={0}>
            {!account ? (
              <Spinner size={16} marginLeft={0} />
            ) : account.logoUrl ? (
              <img src={account.logoUrl} height="48px" />
            ) : (
              <Heading size={500}>{account.name}</Heading>
            )}
          </Pane>
        </Pane>
        <Pane gridArea="body" display="flex" flexDirection="column" alignItems="center">
          {/* Hero Section */}
          <Pane display="flex" height={460} width="100%" paddingX={24} alignItems="center">
            <Pane>
              <Heading size={700} marginBottom={8} display="block" minHeight={28}>
                {account?.programName}
              </Heading>
              <Heading lineHeight="40px" size={800} fontWeight="bold" marginBottom={16}>
                We want you to be well.
              </Heading>
              <Paragraph size={500} fontSize={18} color="muted" marginBottom={24}>
                We have always advocated for your oral wellness and now have a better way to guide your overall dental care.
              </Paragraph>
              <Paragraph size={500} fontSize={18} color="muted" marginBottom={24}>
                Our Wellness Membership Program allows you to choose the plan that's best for you and your family.
              </Paragraph>
              <Heading size={700} marginBottom={8} display="block" minHeight={28}>
                NO DEDUCTIBLES </Heading>
              <Heading size={700} marginBottom={8} display="block" minHeight={28}>
                NO MAXIMUMS </Heading>
              <Heading size={700} marginBottom={8} display="block" minHeight={28}>
                SAVINGS ON YOUR PREVENTIVE AND RESTORATIVE PROCEDURES
              </Heading><br />
              <Pane display="flex" marginBottom={40} visibility={account ? 'visible' : 'hidden'} width={0}>
                <Link to={enrollUrl} marginRight={16}>
                  {/* <Button
                    height={54}
                    width={200}
                    size={500}
                    appearance="primary"
                    intent={buttonIntent}
                    paddingX={48}
                    iconAfter={['far', 'chevron-right']}
                  >
                    Enroll Now
                  </Button> */}
                  <button style={{ backgroundColor: "#306584", height: "50px", width: "120px", border: "0px", color: "white", fontSize: "1rem", borderRadius: "5px", cursor: "pointer", boxShadow: "2px 2px 6px #888" }}>
                    Enroll Now
                  </button>
                </Link>
                {/* <Button height={54} paddingX={48} iconAfter={['fad', 'play-circle']}>
                    Video
                  </Button> */}
              </Pane>
            </Pane>
          </Pane>

          <svg
            width="100%"
            height="100"
            viewBox="0 0 500 100"
            preserveAspectRatio="none"
            style={{ position: 'relative', zIndex: -1, marginTop: -25 }}
          >
            <path d="M0,0 Q250,50 500,0 L500,100 L0,100 Z" fill={colors.neutral.lightest} />
          </svg>

          {/* Value Props Section */}
          <Pane
            paddingTop={0}
            paddingBottom={60}
            paddingX={24}
            width="100%"
            display="flex"
            justifyContent="center"
            background="tint1"
          >
            <Pane maxWidth={1000} display="flex" flexDirection="column" alignItems="center" textAlign="center">
              <Heading lineHeight="40px" size={800} fontWeight="bold" marginBottom={24}>
                Elevate your dental care and save.
              </Heading>
              <Paragraph size={500} fontSize={18} maxWidth={480} color="muted" marginBottom={48}>
                Our easy to use membership plans provide the highest level of comprehensive care for patients without dental benefits and may also supplement your existing benefits.
              </Paragraph>
              {/* <Pane display="grid" gridTemplateRows="1fr 1fr 1fr" gridGap={28} marginBottom={40}>
                <Pane display="flex" flexDirection="column">
                  <Icon icon={['fad', 'stopwatch']} size="4x" color={iconColor} marginBottom={8} />
                  <Heading size={700} marginBottom={8}>
                    Easy
                  </Heading>
                  <Paragraph size={500} color="muted">
                    Sign-up in 90-seconds and instantly access your benefits.{' '}
                  </Paragraph>
                </Pane>
                <Pane display="flex" flexDirection="column">
                  <Icon icon={['fad', 'heart']} size="4x" color={iconColor} marginBottom={8} />
                  <Heading size={700} marginBottom={8}>
                    Comprehensive
                  </Heading>
                  <Paragraph size={500} color="muted">
                    Plans cover your preventive care like cleanings and x-rays.{' '}
                  </Paragraph>
                </Pane>
                <Pane display="flex" flexDirection="column">
                  <Icon icon={['fad', 'wallet']} size="4x" color={iconColor} marginBottom={8} />
                  <Heading size={700} marginBottom={8}>
                    Affordable
                  </Heading>
                  <Paragraph size={500} color="muted">
                    Simple and transparent pricing with discounts on other procedures.{' '}
                  </Paragraph>
                </Pane>
            </Pane> */}
              {/* <Link to={enrollUrl}>
              <Button
                height={54}
                appearance="primary"
                intent={buttonIntent}
                paddingX={48}
                iconAfter={['far', 'chevron-right']}
              >
                Enroll in 90 Seconds
                </Button>
            </Link> */}
            </Pane>
          </Pane>
          {/* Plans Section */}
          <a id="plans" style={{ display: 'block', position: 'relative', top: '-80px', visibility: 'hidden' }} />
          <Pane
            paddingY={60}
            maxWidth={1000}
            display="flex"
            flexDirection="column"
            alignItems="center"
            textAlign="center"
          >
            <Heading size={800} fontWeight="bold" marginBottom={24}>
              Select Your Oral Wellness
            </Heading>
            {global && (
              <PlanCardListNew accountId={account?.id} apolloClient={apolloClient} isSite siteColor={account?.siteColor} />
            )}
          </Pane>

          {/* FAQ Section */}
          <a id="FAQs" style={{ display: 'block', position: 'relative', top: '-80px', visibility: 'hidden' }} />
          <Pane paddingY={60} width="100%" display="flex" justifyContent="center" background="tint1">
            <Pane maxWidth={1000} display="flex" flexDirection="column" alignItems="center" textAlign="center">
              <Heading lineHeight="40px" size={800} fontWeight="bold" marginBottom={48}>
                Frequently Asked Questions
              </Heading>
              <Pane display="grid" gridTemplateColumns="1fr" gridGap={24}>
                <FAQCard
                  iconColor={iconColor}
                  question="Can I transfer my membership to another dentist?"
                  answer="Your membership plan is between our practice and you.  You unfortunately cannot transfer it to another dentist."
                />
                <FAQCard
                  iconColor={iconColor}
                  question="What forms of payment are accepted?"
                  answer="We accept credit and debit cards including Visa, Discover, American Express, and Mastercard."
                />
                <FAQCard
                  iconColor={iconColor}
                  question="How do I schedule an appointment?"
                  answer="Please call our office at the number listed below and we would be happy to set up an appointment for you."
                />
                <FAQCard
                  iconColor={iconColor}
                  question="Can I add my family to my membership plan?"
                  answer="Yes! You can easily add dependents to your membership account."
                />
                <FAQCard
                  iconColor={iconColor}
                  question="How do I edit my billing details?"
                  answer="Just login to our simple Member Portal and click “Billing” to update any information."
                />
                <FAQCard
                  iconColor={iconColor}
                  question="Is Membership for me?"
                  answer="If you are a patient at our practice and do not have dental insurance, a membership plan is a perfect fit for you.  Plans include preventive care and discounts on other procedures."
                />
              </Pane>
            </Pane>
          </Pane>

          {/* Our Practice Section */}
          <a id="contact" style={{ display: 'block', position: 'relative', top: '-80px', visibility: 'hidden' }} />
          <Pane paddingY={60} paddingX={24} width="100%" display="flex" flexDirection="column">
            <Heading size={700} marginBottom={8}>
              Our Practice
            </Heading>
            {account && (
              <>
                <Heading lineHeight="40px" size={800} fontWeight="bold" marginBottom={16}>
                  {account.name}
                </Heading>
                <Text size={500} fontSize={18} color="muted" display="block" marginBottom={16}>
                  Office Code: P2004
                </Text>
                <Heading size={700} marginBottom={8} display="flex" alignItems="center">
                  <Icon icon={['fad', 'phone']} color={iconColor} size="lg" marginRight={8} />
                  {prettyPhoneNumber(account.sitePhone)}
                </Heading>
                {account.siteUrl && (
                  <a href={account.siteUrl}>
                    <Heading size={700} marginBottom={32} display="flex" alignItems="center">
                      <Icon icon={['fad', 'browser']} color={iconColor} size="lg" marginRight={8} />
                      {account.siteUrl?.replace(/(^\w+:|^)\/\//, '')}
                    </Heading>
                  </a>
                )}
                <Text size={500} fontSize={18} color="muted" display="block" marginBottom={32}>
                  Please{' '}
                  <Link size={500} fontSize={18} href="tel:8055160411" inline>
                    call us
                  </Link>{' '}
                  to book an appointment!
                </Text>

                <Heading size={700} marginBottom={16}>
                  Our Offices
                </Heading>
                {account.offices.map(office => (
                  <OfficeCard key={office.id} office={office} marginBottom={8} />
                ))}
                <Heading size={700} marginTop={24} marginBottom={16}>
                  Our Dentists
                </Heading>
                {account.dentists.map(dentist => (
                  <DentistCard key={dentist.id} dentist={dentist} marginBottom={8} />
                ))}
              </>
            )}
          </Pane>
          {/* CTA Section */}
          <Pane paddingY={56} paddingX={24} width="100%" display="flex" justifyContent="center" background="tint1">
            <Pane
              maxWidth={1000}
              display="flex"
              flexDirection="column"
              flex={1}
              justifyContent="center"
              alignItems="center"
            >
              <Heading lineHeight="40px" size={800} fontWeight="bold" textAlign="center" marginBottom={24}>
                Join our membership plan today!
              </Heading>
              <Link to={enrollUrl}>
                {/* <Button
                height={54}
                size={500}
                appearance="primary"
                intent={buttonIntent}
                paddingX={48}
                flexShrink={0}
                iconAfter={['far', 'chevron-right']}
              >
                Sign Up
                </Button> */}
                <button style={{ backgroundColor: "#306584", height: "50px", width: "120px", border: "0px", color: "white", fontSize: "1rem", borderRadius: "5px", cursor: "pointer", boxShadow: "2px 2px 6px #888" }}>
                  Sign Up
                </button>
              </Link>
            </Pane>
          </Pane>

          {/* Legal Section */}
          {/*
          <Pane paddingY={60} paddingX={24} width="100%" display="flex" flexDirection="column" alignItems="center">
            <Heading size={700} marginBottom={24}>
              Important Disclosures
            </Heading>

            <Paragraph fontSize={16} color="muted" textAlign="justify">
              [TruBlu Placeholder]
            </Paragraph>
          </Pane> */}
        </Pane>
        <SiteFooter />
      </div>
    </>
  )
}

export default styled(ProviderMobile)`
  display: grid;
  grid-template-rows: 80px 1fr 80px;
  grid-template-areas:
    'header'
    'body'
    'footer';
`
