import gql from 'graphql-tag'

export const GET_PUBLIC_GLOBAL = gql`
  query PublicGlobal($codeOrSlug: String!) {
    account_public(codeOrSlug: $codeOrSlug) {
      id
      name
      code
      programName
      logoUrl
      siteColor
      siteImage
      siteUrl
      clearentPaymentKey
      sitePhone
      dentists {
        id
        degree
        name
        salutation
      }
      offices {
        id
        name
        address1
        address2
        city
        state
        zip
        phone
      }
    }
  }
`
