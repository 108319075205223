import React from 'react'

import { Pane, Tablist, Tab, Text } from 'evergreen-ui'
import { Link, colors, useMediaQueryContext } from '@pearly/lib'

const Footer = () => {
  const { isMobile } = useMediaQueryContext()

  return (
    <Pane
      gridArea="footer"
      paddingX="32px"
      width="100%"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      backgroundColor={colors.neutral.base}
    >
      <Pane display="flex" flexDirection="column" alignItems="flex-start" marginRight={isMobile ? 24 : 0}>
        <Text color="white" marginBottom={4} size={300}>
          Powered by
        </Text>
        <Text color="white" size={600} fontWeight="bold">
          TruBlu
        </Text>
      </Pane>
      <Tablist>
        <Link href="http://www.planforhealth.com/legal/terms" target="_blank">
          <Tab color="white">Terms of Use</Tab>
        </Link>
        <Tab color="white" href="http://www.planforhealth.com/legal/privacy" target="_blank">
          Privacy
        </Tab>
      </Tablist>
    </Pane>
  )
}

export default Footer
