import React, { lazy, Suspense, useEffect } from 'react'
import { navigate } from 'gatsby'
import { Switch, Route } from 'react-router-dom'
import { useApolloClient } from '@apollo/react-hooks'

import Helmet from 'react-helmet'
import { withPrefix } from "gatsby"

import ApolloProvider from 'components/apollo-provider'
import { ModalProvider } from 'components/modal-provider'
import { PublicGlobalProvider } from 'components/public-global-provider'
import { Pane } from 'evergreen-ui'
import { Spinner, useMediaQueryContext } from '@pearly/lib'

import Provider from 'views/provider'
import ProviderMobile from 'views/provider-mobile'

const clearentHPPScript = process.env.GATSBY_CLEARENT_HPP_SCRIPT

const FirebaseProvider = lazy(() => import('components/firebase-provider'))
const Enroll = lazy(() => import('views/enroll'))
const Invitation = lazy(() => import('views/invitation'))

const IndexPage = () => {
  return (
    <div>
      <Helmet>
        <script type="text/javascript" id="clearent-hpp" src={`${clearentHPPScript}`}></script>
        <script src={withPrefix('clearent-init.js')} type="text/javascript" />
      </Helmet>
      <ApolloProvider>
        <ApolloConsumer />
      </ApolloProvider>
    </div>
  )
}

export default IndexPage

const ApolloConsumer = () => {
  const apolloClient = useApolloClient()
  const { isMobile } = useMediaQueryContext()

  return (
    <ModalProvider apolloClient={apolloClient}>
      <Route path="/:providerParam">
        <PublicGlobalProvider>
          <Switch>
            <Route path="/:providerParam/(enroll|invitation)">
              <Suspense
                fallback={
                  <Pane height="100vh" display="flex" alignItems="center">
                    <Spinner />
                  </Pane>
                }
              >
                <FirebaseProvider>
                  <Route path="/:providerParam/enroll">
                    <Enroll />
                  </Route>
                  <Route path="/:providerParam/invitation">
                    <Invitation />
                  </Route>
                </FirebaseProvider>
              </Suspense>
            </Route>


            <Route path="/:providerParam">{isMobile ? <ProviderMobile /> : <Provider />}</Route>
          </Switch>
        </PublicGlobalProvider>
      </Route>
      <Route
        exact
        path="/"
        component={() => {
          if (typeof window !== 'undefined') {
            navigate('/dashboard')
          }
          return null
        }}
      />
    </ModalProvider>
  )
}
