import React, { useState } from 'react'

import { Pane, Heading, Paragraph } from 'evergreen-ui'
import { Card, Icon } from '@pearly/lib'

export type Props = {
  question: string
  answer: string
  iconColor?: 'primary' | 'success' | 'warning' | 'danger'
  isOpen?: boolean
}

const FAQCard = ({ question, answer, iconColor = 'primary', isOpen: isOpenProp = false }: Props) => {
  const [isOpen, setIsOpen] = useState(isOpenProp)
  return (
    <Pane textAlign="left">
      <Card onClick={() => setIsOpen(!isOpen)} padding={0} width={300} cursor="pointer">
        <Pane padding={16} elevation={0} display="flex" justifyContent="space-between">
          <Heading size={500} marginRight={4}>
            {question}
          </Heading>
          <Icon icon={['fad', 'question']} size="lg" color={iconColor} />
        </Pane>
        <Pane
          paddingX={16}
          height={isOpen ? 'auto' : 0}
          paddingY={isOpen ? 16 : 0}
          opacity={isOpen ? 1 : 0}
          transition={`height 250ms, padding 250ms, opacity ${isOpen ? '125ms 125ms' : '125ms 0ms'}`}
        >
          <Paragraph size={500}>{answer}</Paragraph>
        </Pane>
      </Card>
    </Pane>
  )
}

export default FAQCard
