import React, { createContext, useContext, useState } from 'react'
import { useParams } from 'react-router-dom'

import { CreditCardProvider } from '@pearly/lib'

import { useQuery } from '@apollo/react-hooks'
import { GET_PUBLIC_GLOBAL } from 'graphql/_public-global'
//import { GET_PUBLIC_GLOBAL } from '../graphql/_public-global'
import * as Types from 'types'
import { Redirect } from 'react-router-dom'

type GlobalContext = {
  loading: boolean
  global?: Types.PublicGlobal
}

const Context = createContext<GlobalContext>({ loading: true, global: undefined })
export const usePublicGlobal = () => useContext(Context)

export const PublicGlobalProvider = ({ children }: { children: React.ReactNode }) => {
  const { providerParam } = useParams<{ providerParam: string }>()
  const { loading, data } = useQuery<Types.PublicGlobal, Types.PublicGlobalVariables>(GET_PUBLIC_GLOBAL, {
    variables: {
      codeOrSlug: providerParam.toLowerCase()
    }
  })

  const creditCardEngine = {
    engine: "Clearent",
    url: "",
    paymentKey: data?.account_public?.clearentPaymentKey
  }
  if (data && data?.account_public==null) {
    return <Redirect to='/' />
  }
  
  return <CreditCardProvider engine={creditCardEngine}>
      <Context.Provider value={{ loading, global: data }}>
      {children}
      </Context.Provider>
  </CreditCardProvider>
}
