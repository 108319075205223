import React, { useEffect, useState } from 'react'
import * as Types from 'types'

import { Pane, Heading } from 'evergreen-ui'
import { Button, Spinner, Link } from '@pearly/lib'
import { Link as GatsbyLink } from 'gatsby'

import HeaderButton from 'components/header-button'

import TBLogo from '../components/assets/TB-Logos-Final_PlanForHealth.png';

export type Props = {
  account?: Types.Provider_account_public | null
  enrollUrl: string
}

const Header = ({ account, enrollUrl }: Props) => {
  const [headerElevation, setHeaderElevation] = useState(false)

  useEffect(() => {
    window.onscroll = e => {
      if (window.scrollY > 0) {
        setHeaderElevation(true)
      } else {
        setHeaderElevation(false)
      }
    }
  }, [])
  
  const img = TBLogo;

  return (
    <Pane
      gridArea="header"
      paddingX="32px"
      width="100%"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      position="fixed"
      zIndex={3}
      height="80px"
      elevation={headerElevation ? 1 : undefined}
      background={headerElevation ? 'tint1' : undefined}
      transition="box-shadow 240ms"
    >
      {/* <Pane lineHeight={0}>
        {!account ? (
          <Spinner size={16} marginLeft={0} />
        ) : account.logoUrl ? (
          <img src={account.logoUrl} height="48px" />
          ) : (
            <Heading size={700}>{account.programName}</Heading>
            )}
          </Pane> */}
          <img src={img} height="48px" />

      {/* <img src={logo} height="50px" /> */}
      <Pane display="flex" alignItems="center">
        <a href="#plans">
          <HeaderButton>Plans</HeaderButton>
        </a>
        <a href="#FAQs">
          <HeaderButton>FAQs</HeaderButton>
        </a>
        <a href="#contact">
          <HeaderButton>Contact</HeaderButton>
        </a>
        <GatsbyLink to="/dashboard">
          <HeaderButton>Log In</HeaderButton>
        </GatsbyLink>
        <Link to={enrollUrl}>
          <HeaderButton>
            Enroll Now
            </HeaderButton>
        </Link>
      </Pane>
    </Pane>
  )
}

export default Header
