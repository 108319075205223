import React from 'react'
import styled from 'styled-components'
import Helmet from 'react-helmet'

import * as Types from 'types'

import { useApolloClient } from '@apollo/react-hooks'

import { useParams } from 'react-router-dom'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

import { Pane, Heading, Paragraph, Text } from 'evergreen-ui'

import SiteHeader from 'components/site-header'
import SiteFooter from 'components/site-footer'

import FAQCard from 'components/faq-card'
import { usePublicGlobal } from 'components/public-global-provider'

import header from '../components/assets/header-background1.jpeg';
import jumbo from '../components/assets/jumbotron-background.png';

import {
  Button,
  Icon,
  OfficeCard,
  DentistCard,
  Link,
  useMediaQueryContext,
  colors,
  prettyPhoneNumber,
  PlanCardListNew
} from '@pearly/lib'

export const query = graphql`
  query {
    COUPLE: file(relativePath: { eq: "img/smiling-couple.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    MAN: file(relativePath: { eq: "img/smiling-man.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    WOMAN: file(relativePath: { eq: "img/smiling-woman.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

export type Props = {
  className?: string
}

const colorMap = {
  [Types.SiteColor.BLUE]: { buttonIntent: 'none' as const, iconColor: 'primary' as const },
  [Types.SiteColor.GREEN]: { buttonIntent: 'success' as const, iconColor: 'success' as const },
  [Types.SiteColor.ORANGE]: { buttonIntent: 'warning' as const, iconColor: 'warning' as const },
  [Types.SiteColor.RED]: { buttonIntent: 'danger' as const, iconColor: 'danger' as const }
}

const Provider = ({ className }: Props) => {
  const { providerParam } = useParams<{ providerParam: string }>()
  const enrollUrl = `/${providerParam}/enroll`
  const apolloClient = useApolloClient()

  const { global } = usePublicGlobal()
  const account = global?.account_public

  const { buttonIntent, iconColor } = colorMap[account?.siteColor ?? Types.SiteColor.BLUE]

  const staticData = useStaticQuery(query)
  const { isTablet } = useMediaQueryContext()

  const img1 = header;
  const img2 = jumbo;

  return (
    <>
        <Helmet>
        <title>{global?.account_public?.programName}</title>
            {providerParam !== 'demo' && <link rel="canonical" href="https://member.planforhealth.com" />}
        </Helmet>
        {/* <div className={className}> */}
            <SiteHeader account={account} enrollUrl={enrollUrl} />
            <Pane gridArea="body" display="flex" flexDirection="column" alignItems="center">
                {/* Hero Section */}
                <div style={{  
                    backgroundImage: `url(${img1})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    width: '100%'
                }}>
                <Pane display="flex" height={700} paddingTop={60} width="100%" paddingLeft={84} alignItems="center">
                    <Pane flex={1.2} marginRight={40} >
                        <Heading size={700} marginBottom={20} marginTop={30} display="block" minHeight={28}>
                            {account?.programName}
                        </Heading>
                        <Heading lineHeight="40px" fontSize={40} fontWeight="bold" marginBottom={40}>
                            We want you to be well.
                        </Heading>
                        <Paragraph size={500} fontSize={18} color="dark" marginBottom={24} width="75%">
                            We have always advocated for your oral wellness and now have a better way to guide your overall dental care.
                        </Paragraph>
                        <Paragraph size={500} fontSize={18} color="dark" marginBottom={24} width="75%">
                            Our Wellness Membership Program allows you to choose the plan that's best for you and your family.
                        </Paragraph>
                        <Pane width="100%" paddingLeft={40} marginBottom={60}>
                            <Paragraph size={500} fontSize={18} color="dark" marginBottom={8}>
                                <strong>NO DEDUCTIBLES</strong>
                            </Paragraph>
                            <Paragraph size={500} fontSize={18} color="dark" marginBottom={8}>
                                <strong>NO MAXIMUMS</strong>
                            </Paragraph>
                            <Paragraph size={500} fontSize={18} color="dark" marginBottom={8}>
                                <strong>SAVINGS ON YOUR PREVENTIVE AND RESTORATIVE PROCEDURES</strong>
                            </Paragraph>
                        </Pane>
                        {/* <Pane display="flex" marginBottom={40} visibility={account ? 'visible' : 'hidden'}> */}
                        <Pane display="flex" marginBottom={40} width="100%" paddingLeft={40}>
                            <Link to={enrollUrl} marginRight={16}>
                                {/* <Button height={54} size={500} appearance="primary" intent={buttonIntent} paddingX={48}>
                                    Enroll Now
                                </Button> */}
                                <button style={{backgroundColor: "#306584", height: "60px", width:"180px", border: "0px", color:"white", fontSize:"1.4rem", borderRadius:"5px", cursor: "pointer", boxShadow:"2px 2px 6px #888"}}>
                                    Enroll Now
                                </button>
                            </Link>
                        </Pane>
                    </Pane>
                    <Pane flex={2} height="100%" flexDirection="column" justifyContent="flex-end" display={isTablet ? 'none' : 'flex'}>
                    </Pane>
                </Pane>
                </div>
                <div style={{  
                    backgroundImage: `url(${img2})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    width: '100%',
                    height: '500px'
                }}>
                <Pane display="flex" height={500} width="100%">
                    <Pane flex={2} >
                    </Pane>  
                    <Pane flex={1.2} flexDirection="column" paddingRight={60} paddingTop={80} justifyContent="flex-end">
                        <Pane alignItems="center" textAlign="center">
                            <Heading lineHeight="40px" fontSize={40} fontWeight="bold" marginBottom={40}>
                                Elevate your dental care and save.
                            </Heading>
                            <Paragraph size={500} fontSize={18} color="dark" marginBottom={24} >
                                Our easy to use membership plans provide the highest level of comprehensive care for patients without dental benefits and may also supplement your existing benefits.
                            </Paragraph>
                        </Pane>
                    </Pane>
                </Pane>
                </div>

          {/* Plans Section */}
          <a id="plans" style={{ display: 'block', position: 'relative', top: '-80px', visibility: 'hidden' }} />
          <Pane
            paddingY={88}
            maxWidth={1000}
            display="flex"
            flexDirection="column"
            alignItems="center"
            textAlign="center"
          >
            <Heading fontSize={40} fontWeight="bold" marginBottom={24}>
              Select Your Oral Wellness
            </Heading>
            {/* {global && ( */}
              <PlanCardListNew accountId={account?.id} apolloClient={apolloClient} isSite siteColor={account?.siteColor} />
            {/* )} */}
          </Pane>

          {/* FAQ Section */}
          <a id="FAQs" style={{ display: 'block', position: 'relative', top: '-80px', visibility: 'hidden' }} />
          <Pane paddingY={88} width="100%" display="flex" justifyContent="center" background="tint1">
            <Pane maxWidth={1000} display="flex" flexDirection="column" alignItems="center" textAlign="center">
              <Heading lineHeight="40px" fontSize={40} fontWeight="bold" marginBottom={48}>
                Frequently Asked Questions
              </Heading>
              <Pane display="grid" gridTemplateColumns="1fr 1fr" gridGap={24}>
                <FAQCard
                  iconColor={iconColor}
                  question="Can I transfer my membership to another dentist?"
                  answer="Your membership plan is between our practice and you.  You unfortunately cannot transfer it to another dentist."
                />
                <FAQCard
                  iconColor={iconColor}
                  question="What forms of payment are accepted?"
                  answer="We accept credit and debit cards including Visa, Discover, American Express, and Mastercard."
                />
                <FAQCard
                  iconColor={iconColor}
                  question="How do I schedule an appointment?"
                  answer="Please call our office at the number listed below and we would be happy to set up an appointment for you."
                />
                <FAQCard
                  iconColor={iconColor}
                  question="Can I add my family to my membership plan?"
                  answer="Yes! You can easily add dependents to your membership account."
                />
                <FAQCard
                  iconColor={iconColor}
                  question="How do I edit my billing details?"
                  answer="Just login to our simple Member Portal and click “Billing” to update any information."
                />
                <FAQCard
                  iconColor={iconColor}
                  question="Is Membership for me?"
                  answer="If you are a patient at our practice and do not have dental insurance, a membership plan is a perfect fit for you.  Plans include preventive care and discounts on other procedures."
                />
              </Pane>
            </Pane>
          </Pane>

          {/* Our Practice Section */}
          <a id="contact" style={{ display: 'block', position: 'relative', top: '-80px', visibility: 'hidden' }} />
          <Pane paddingY={88} width="100%" maxWidth={800} display="flex" flexDirection="row">
            <Pane flex={1.2} marginRight={48}>
              <Heading size={700} marginBottom={8}>
                Our Practice
              </Heading>
              {account && (
                <>
                  <Heading lineHeight="40px" fontSize={40} fontWeight="bold" marginBottom={16}>
                    {account.name}
                  </Heading>
                  <Text size={500} fontSize={18} color="muted" display="block" marginBottom={32}>
                    Office Code: {account.code.toUpperCase()}
                  </Text>
                  <Heading size={700} marginBottom={8} display="flex" alignItems="center">
                    <Icon icon={['fad', 'phone']} color={iconColor} size="lg" marginRight={8} />
                    {prettyPhoneNumber(account.sitePhone)}
                  </Heading>
                  {account.siteUrl && (
                    <a href={account.siteUrl}>
                      <Heading size={700} marginBottom={32} display="flex" alignItems="center">
                        <Icon icon={['fad', 'browser']} color={iconColor} size="lg" marginRight={8} />
                        {account.siteUrl}
                      </Heading>
                    </a>
                  )}
                  <Text size={500} fontSize={18} color="muted" display="block" marginBottom={32}>
                    Please{' '}
                    <Link size={500} fontSize={18} href={`tel:${account.sitePhone}`} inline>
                      call us
                    </Link>{' '}
                    to book an appointment!
                  </Text>
                </>
              )}
            </Pane>
            {account && (
              <Pane flex={1}>
                <Heading size={700} marginBottom={16}>
                  Our Offices
                </Heading>
                {account.offices.map(office => (
                  <OfficeCard key={office.id} office={office} marginBottom={8} />
                ))}
                <Heading size={700} marginTop={24} marginBottom={16}>
                  Our Dentists
                </Heading>
                {account.dentists.map(dentist => (
                  <DentistCard key={dentist.id} dentist={dentist} marginBottom={8} />
                ))}
              </Pane>
            )}
          </Pane>

          {/* CTA Section */}
          <Pane padding={56} width="100%" display="flex" justifyContent="center" background="tint1">
            <Pane
              maxWidth={1000}
              display="flex"
              flexDirection="row"
              flex={1}
              justifyContent="center"
              alignItems="center"
            >
              <Heading lineHeight="40px" fontSize={40} paddingRight={20} fontWeight="bold">
                Join our membership plan today!
              </Heading>
              <Link to={enrollUrl}>
                {/* <Button
                  height={54}
                  size={500}
                  appearance="primary"
                  intent={buttonIntent}
                  paddingX={48}
                  marginLeft={48}
                  flexShrink={0}
                  iconAfter={['far', 'chevron-right']}
                >
                  Sign Up
                </Button> */}
                <button style={{backgroundColor: "#306584", height: "60px", width:"160px", border: "0px", color:"white", fontSize:"1.4rem", borderRadius:"5px", cursor: "pointer", boxShadow:"2px 2px 6px #888"}}>
                Sign Up >
                </button>
              </Link>
            </Pane>
          </Pane>

          {/* Legal Section */} 
          {/*
          <Pane paddingY={64} width="100%" maxWidth={800} display="flex" flexDirection="column" alignItems="center">
            <Heading size={700} marginBottom={24}>
              Important Disclosures
            </Heading>

            <Paragraph fontSize={16} color="muted" textAlign="justify">
              [TruBlu Placeholder]
            </Paragraph>
          </Pane>
          */}
        </Pane>
        <SiteFooter />
      {/* </div> */}
    </>
  )
}

export default styled(Provider)`
  display: grid;
  grid-template-rows: 80px 1fr 80px;
  grid-template-areas:
    'header'
    'body'
    'footer';
  @media only screen and (max-width: 768px) {
    display: none;
  }
`
